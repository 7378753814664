import Vue from 'vue'
import Vuetify from 'vuetify'
import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    iconfont: 'mdi'
  },
  theme: {
    light: true,
    themes: {
      light: {
        primary: '#039BE5',
        gray: '#696E88',
        'dark-grey': '#2D323E',
        'blue-light': '#F1F4F9',
        secondary: '#EB5757'
      }
    }
  }
})