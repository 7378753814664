<template>
  <div
    ref="global-notification"
    class="global-notification"
    :style="notificationStyle"
  >
    <div
      v-for="notification in notifications"
      :key="notification.id"
    >
      <div :class="[`global-notification--${notification.type}`, { animate: notification.show }]">
        <v-icon
          v-if="notification.closeButton"
          class="close-icon"
          small
          @click="hide(notification.id)"
        >
          close
        </v-icon>
        <div v-html="notification.html" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Alert',
  data () {
    return {
      duration: 4000,
      notifications: [],
      notificationStyle: {}
    }
  },
  watch: {
    notifications: {
      deep: true,
      handler (value) {
        if (value.length) {
          this.$nextTick(() => {
            this.calculateTranslateWidth()
          })
        }
      }
    }
  },
  mounted () {
    this.$notification.$on('success', this.onSuccess)
    this.$notification.$on('info', this.onInfo)
    this.$notification.$on('danger', this.onDanger)
    this.$notification.$on('warning', this.onWarning)
    this.$notification.$on('hide', this.hide)
  },
  beforeDestroy () {
    this.$notification.$off('success', this.onSuccess)
    this.$notification.$off('info', this.onInfo)
    this.$notification.$off('danger', this.onDanger)
    this.$notification.$off('warning', this.onWarning)
    this.$notification.$off('hide', this.hide)
  },
  methods: {
    calculateTranslateWidth () {
      const notification = this.$refs['global-notification']

      if (notification) {
        const translateWidth = notification.offsetWidth / 2
        const translateValue = `translateX(-${translateWidth}px)`

        this.notificationStyle = { transform: translateValue }
      } else {
        this.notificationStyle = {}
      }
    },
    onSuccess (options) {
      if (!options) options = {}
      options.type = 'success'
      if (!options.html) options.html = 'Updated successfully!'
      this.show(options)
    },
    onDanger (options) {
      if (!options) options = {}
      options.type = 'danger'
      if (!options.html) options.html = 'Something went wrong!'
      this.show(options)
    },
    onInfo (options) {
      if (!options) options = {}
      options.type = 'info'
      this.show(options)
    },
    onWarning (options) {
      if (!options) options = {}
      options.type = 'warning'
      this.show(options)
    },
    show (options) {
      if (!options.id) options.id = Math.ceil(Math.random() * 50000)
      this.notifications.push(options)

      // to make animated transition
      setTimeout(() => {
        this.$set(options, 'show', true)

        if (!options.permanent) {
          setTimeout(() => {
            this.hide(options.id)
          }, options.duration || this.duration)
        }
      }, 0)
    },
    hide (id) {
      const index = this.notifications.findIndex(n => n.id === id)
      if (index > -1) { this.notifications.splice(index, 1) }
    }
  }
}
</script>
<style lang="sass" scoped>
.global-notification
  text-align: center
  position: fixed
  top: 14px
  left: 50%
  // transform: translateX(-100px)
  z-index: 5000000
  min-width: 280px
  max-width: 400px
  width: fit-content

.global-notification--success,
.global-notification--danger,
.global-notification--warning,
.global-notification--info
  transition: transform 100ms ease-in-out
  transform: translateY(-5px)
  border-width: 1px
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)
  border-radius: 2px
  box-sizing: border-box
  padding: 10px 12px
  line-height: 1em
  margin-bottom: 10px
  text-align: center
  @apply text-sm

  &.animate
    transform: translateY(0)

.global-notification--success
  color: #285b2a
  background-color: #dbefdc
  border-color: #cde9ce

.global-notification--danger
  color: #7f231c
  background-color: #fdd9d7
  border-color: #fdd9d7

.global-notification--warning
  color: #854f00
  background-color: #ffeacc
  border-color: #ffeacc

.global-notification--info
  color: #285b2a
  background-color: #cceae7
  border-color: #cceae7

.close-icon
  cursor: pointer
  float: right

  &:hover
    opacity: .8

</style>