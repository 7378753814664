/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

import '@mdi/font/css/materialdesignicons.css'
// import 'vuetify/dist/vuetify.min.css' 

import '../css/tailwind.css';

window.Vue = require('vue');
Vue.prototype.api = axios; 

import { money, showAlert } from './helpers'
Vue.prototype.money = money
Vue.prototype.showAlert = showAlert
Vue.prototype.$notification = new Vue()
Vue.prototype._ = require('lodash')
Vue.prototype.moment = require('moment')

import vuetify from './plugins/vuetify'
Vue.use(vuetify)

// import Vuetify from 'vuetify'

// Vue.use(Vuetify)

Vue.component('alert-component', require('./components/globals/Alert.vue').default)
Vue.component('dashboard-component', require('./components/dashboard/DashboardComponent.vue').default)

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */


 import store from './store'


const app = new Vue({
    el: '#app',
    store,
    vuetify: vuetify
});
