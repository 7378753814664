const axios = require('axios')
import { sum } from '../../helpers'
import _ from 'lodash'

export const ExpenseCategoriesModule = {
    namespaced: true,
    state: {
        categories: {},
        loadingCategories: false,
    },
    getters: {
        getCategories: state => state.categories.data,
        getPager: state => state.categories.pager,
        loadingCategories: state => state.loadingCategories
    },

    mutations: {
        SET_CATEGORIES(state, categories) {
            state.categories = categories
        },
        SET_LOADING_STATUS(state, status) {
            state.loadingCategories = status
        }
    },
    actions: {
        /**
         * Loads categories from the api
         * @param {*} param0 
         * @param {*} force 
         */
        async loadCategories({ commit, dispatch }, force = false) {
            commit('SET_LOADING_STATUS', true)
            const categories = (await axios.get('/api/expense-categories'))
            categories.data = categories.data.sort((a, b) => {
                if(a.name > b.name) return 1
                else if(a.name < b.name) return -1
                else return 0
            })
            commit('SET_CATEGORIES', categories)
            commit('SET_LOADING_STATUS', false)
        },

        /**
         * Adds categories
         * @param {*} param0 
         * @param {*} payload 
         */
        async addCategory({ dispatch }, payload) {
            await axios.post('/api/expense-categories', payload)
            dispatch('loadCategories')
        },

        /**
         * Removes categories via api
         * @param {*} param0 
         * @param {*} categoriesId 
         */
        async removeCategory( { dispatch, state }, categoriesId ){
            await axios.delete(`/api/expense-categories/${categoriesId}`)
            dispatch('loadCategories')
        }

    }

}