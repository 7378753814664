const axios = require('axios')
import { sum } from '../../helpers'
import _ from 'lodash'

export const SubscriptionsModule = {
    namespaced: true,
    state: {
        subscriptions: [],
        loadingSubscriptions: false,
        subscriptionsTotal: 0.0
    },
    getters: {
        getSubscriptions: state => state.subscriptions,
        getSubscriptionsTotal: state => state.subscriptionsTotal,
        loadingSubscriptions: state => state.loadingSubscriptions
    },

    mutations: {
        SET_SUBSCRIPTIONS(state, subscriptions) {
            state.subscriptions = subscriptions
        },
        SET_SUBSCRIPTION_TOTAL(state, total) {
            state.subscriptionsTotal = total
        },
        SET_LOADING_STATUS(state, status) {
            state.loadingSubscriptions = status
        }
    },
    actions: {
        /**
         * Loads subscriptions from the api
         * @param {*} param0 
         * @param {*} force 
         */
        async loadSubscriptions({ commit, dispatch }, force = false) {
            commit('SET_LOADING_STATUS', true)
            const subscriptions = (await axios.get('/api/subscriptions')).data
            commit('SET_SUBSCRIPTIONS', subscriptions)
            dispatch('setSubscriptionsTotal')
            commit('SET_LOADING_STATUS', false)
        },

        /**
         * Sets the subscriptions total
         * @param {*} param0 
         */
        setSubscriptionsTotal({ state, commit }) {
            const subscriptions = state.subscriptions
            commit('SET_SUBSCRIPTION_TOTAL', sum(subscriptions.filter(subscription => subscription.status === 'active')
                .map(subscription => subscription.amount)))
        },

        /**
         * Adds subscription
         * @param {*} param0 
         * @param {*} payload 
         */
        async addSubscription({ dispatch }, payload) {
            await axios.post('/api/subscriptions', payload)
            dispatch('loadSubscriptions')
        },

        /**
         * Removes subscription via api
         * @param {*} param0 
         * @param {*} subscriptionId 
         */
        async removeSubscription({ commit, state }, subscriptionId) {
            await axios.delete(`/api/subscriptions/${subscriptionId}`)
            const deletedsubscription = state.subscriptions.find(subscription => subscription.id === subscriptionId)
            const subscriptions = state.subscriptions.filter(subscription => subscription.id !== subscriptionId)
            commit('SET_SUBSCRIPTIONS', subscriptions)
            if (deletedsubscription)
                commit('SET_SUBSCRIPTION_TOTAL', state.subscriptionsTotal - deletedsubscription.amount)
        }

    }

}