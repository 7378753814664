const axios = require('axios')
import { sum } from '../../helpers'
import _ from 'lodash'
import moment from 'moment'

export const BudgetsModule = {
    namespaced: true,
    state: {
        budgets: [],
        loadingBudgets: false,
        selectedBudget: {
          expenses: [],
          incomes: [],
          subscriptions: [],
          expense_plan: []
        }
    },
    getters: {
        getBudgets: state => state.budgets,
        loadingBudgets: state => state.loadingBudgets,
        getSelectedBudget: state => state.selectedBudget,
        getSelectedTotalExpenses: state => {
            if (!state.selectedBudget.expenses) return 0
            let expenses = _.cloneDeep(state.selectedBudget.expenses)
            if (state.selectedBudget.closed) {
                // Filter out budget reconciliation expense for closed budget
                // this will be the last expense in the budget
                if(expenses.length > 0)
                 expenses = expenses.slice(0, expenses.length - 1)
            }


            // Add in subscriptions if budget isn't closed
            expenses.push(...state.selectedBudget.subscriptions)

            return parseFloat(sum(
                expenses.map(expense => expense.amount)
            ).toFixed(2))
        },
        getSelectedTotalIncomes: state => {
            if (!state.selectedBudget.incomes) return 0
            return parseFloat(sum(
                state.selectedBudget.incomes.map(income => income.amount)
            ).toFixed(2))
        },
        getSelectedTotalExpensePlan: state => {
            if (!state.selectedBudget.expense_plan) return 0
            return parseFloat(sum(
                state.selectedBudget.expense_plan.map(expense => expense.amount)
            ).toFixed(2))
        }
    },

    mutations: {
        SET_BUDGETS(state, budgets) {
            state.budgets = budgets
        },
        SET_LOADING_STATUS(state, status) {
            state.loadingBudgets = status
        },
        SET_SELECTED_BUDGET(state, budget) {
            state.selectedBudget = budget
        }
    },
    actions: {
        /**
         * Loads budgets from the api
         * @param {*} param0
         * @param {*} force
         */
        async loadLatestBudgets({ commit, state, dispatch }, force = false) {
            commit('SET_LOADING_STATUS', true)
            const budgets = (await axios.get(`/api/budgets/user/latest-n?n=12`)).data

            // Add date to budget names
            budgets.map(budget => {
                budget.name = `${budget.name} - ${moment(budget.applicable_month, 'YYYY-MM-DD').format('MMMM YYYY')}`
            })
            commit('SET_BUDGETS', budgets)
            commit('SET_LOADING_STATUS', false)
            if (!state.selectedBudget.id && budgets.length > 0) {
                dispatch('selectBudget', budgets[0])
            } else if (state.selectedBudget.id && budgets.length > 0) {
                const budget = budgets.find(budget => budget.id === state.selectedBudget.id)
                if (budget) dispatch('selectBudget', budget)
            }
        },

        selectBudget({ commit }, budget) {
            commit('SET_SELECTED_BUDGET', budget)
        },

        /**
         * Records an expense to the selected budget.
         * @param {*} param0
         * @param {*} payload
         */
        async addExpenseToSelectedBudget({ commit, state }, payload) {
            // Add selected budget id
            payload.budget_id = state.selectedBudget.id
            const expense = (await axios.post('/api/expenses', payload)).data
            // We add the expense to the selected budget's expenses
            const selectedBudget = _.cloneDeep(state.selectedBudget)
            selectedBudget.expenses.push(expense)
            commit('SET_SELECTED_BUDGET', selectedBudget)

        },

        /**
         * Adds expense categories to selected budget.
         * @param {*} param0
         * @param {*} payload
         */
        async addExpenseCategoryToSelectedBudget({ dispatch, state }, payload) {
            const budgetId = state.selectedBudget.id
            await axios.put(`/api/budgets/${budgetId}/expenses`, payload)
            dispatch('loadLatestBudgets')
        },

        /**
         * Adds subscriptions to selected budget
         * @param {*} param0
         * @param {*} payload
         */
        async addSubscriptionsToSelectedBudget({ dispatch, state }, payload) {
            const budgetId = state.selectedBudget.id
            await axios.put(`/api/budgets/${budgetId}/subscriptions`, payload)
            dispatch('loadLatestBudgets')
        },

        /**
         * Removes subscription from seleted budget
         * @param {*} param0
         * @param {*} subscriptionId
         */
        async removeSubscriptionFromSelectedBudget({ dispatch, state }, subscriptionId) {
            const budgetId = state.selectedBudget.id
            await axios.delete(`/api/budgets/${budgetId}/subscriptions/${subscriptionId}`)
            dispatch('loadLatestBudgets')
        },

        /**
         * Adds incomes to selected budget
         * @param {*} param0
         * @param {*} payload
         */
        async addIncomesToSelectedBudget({ dispatch, state }, payload) {
            const budgetId = state.selectedBudget.id
            await axios.put(`/api/budgets/${budgetId}/incomes`, payload)
            dispatch('loadLatestBudgets')
        },

        /**
         * Removes income from selected budget
         * @param {*} param0
         * @param {*} incomeId
         */
        async removeIncomeFromSelectedBudget({ dispatch, state }, incomeId) {
            const budgetId = state.selectedBudget.id
            await axios.delete(`/api/budgets/${budgetId}/incomes/${incomeId}`)
            dispatch('loadLatestBudgets')
        },

        /**
         * Removes an expense plan from the budget.
         * @param {*} param0
         * @param {*} planId
         */
        async removeExpensePlanFromSelectedBudget({ dispatch, state }, planId) {
            const budgetId = state.selectedBudget.id
            await axios.delete(`/api/budgets/${budgetId}/expenses/${planId}`)
            dispatch('loadLatestBudgets')
        },

        async removeExpenseFromSelectedBudget({ dispatch }, expenseId) {
            await axios.delete(`/api/expenses/${expenseId}`)
            dispatch('loadLatestBudgets')
        },

        /**
         * Edit a budget expense plan
         * @param {*} param0
         * @param {*} payload
         */
        async editExpensePlan({ dispatch, state }, payload){
            const budgetId = state.selectedBudget.id
            const planId = payload.planId
            delete payload.id
            await axios.put(`/api/budgets/${budgetId}/expenses/${planId}`, payload)
            dispatch('loadLatestBudgets')
        },

        /**
         * Adds budget
         * @param {*} param0
         * @param {*} payload
         */
        async addBudget({ dispatch }, payload) {
            await axios.post('/api/budgets', payload)
            // Deselect the selected budget
            // so that the newly added budget is selected
            dispatch('selectBudget', {})
            dispatch('loadLatestBudgets')
        },

        /**
         * Removes budget via api
         * @param {*} param0
         * @param {*} budgetId
         */
        async removeBudget({ dispatch }, budgetId) {
            await axios.delete(`/api/budgets/${budgetId}`)
            dispatch('loadLatestBudgets')
        },

        /**
         * Closes the selected budget
         * @param {*} param0
         * @param {*} payload
         */
        async closeSelectedBudget({ state, dispatch }, payload) {
            const budgetId = state.selectedBudget.id
            await axios.put(`/api/budgets/${budgetId}/close`, payload)
            // If rolling over, we ensure that the new budget
            // is selected after creation
            if (payload.rollover)
                dispatch('selectBudget', {})
            dispatch('loadLatestBudgets')
        }

    }

}
