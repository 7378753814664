const axios = require('axios')
import { sum } from '../../helpers'
import _ from 'lodash'

export const IncomesModule = {
    namespaced: true,
    state: {
        incomes: [],
        loadingIncomes: false,
        incomesTotal: 0.0
    },
    getters: {
        getIncomes: state => state.incomes,
        getIncomesTotal: state => state.incomesTotal,
        loadingIncomes: state => state.loadingIncomes
    },

    mutations: {
        SET_INCOMES(state, incomes) {
            state.incomes = incomes
        },
        SET_INCOME_TOTAL(state, total) {
            state.incomesTotal = total
        },
        SET_LOADING_STATUS(state, status) {
            state.loadingIncomes = status
        }
    },
    actions: {
        /**
         * Loads incomes from the api
         * @param {*} param0 
         * @param {*} force 
         */
        async loadIncomes({ commit, dispatch }, force = false) {
            commit('SET_LOADING_STATUS', true)
            const incomes = (await axios.get('/api/incomes')).data
            commit('SET_INCOMES', incomes)
            commit('SET_LOADING_STATUS', false)
            commit('SET_INCOME_TOTAL', sum(incomes.map(income => income.amount)))
        },

        /**
         * Adds income
         * @param {*} param0 
         * @param {*} payload 
         */
        async addIncome({ dispatch }, payload) {
            await axios.post('/api/incomes', payload)
            dispatch('loadIncomes')
        },

        /**
         * Removes income via api
         * @param {*} param0 
         * @param {*} incomeId 
         */
        async removeIncome({ commit, state }, incomeId) {
            await axios.delete(`/api/incomes/${incomeId}`)
            const deletedIncome = state.incomes.find(income => income.id === incomeId)
            const incomes = state.incomes.filter(income => income.id !== incomeId)
            commit('SET_INCOMES', incomes)
            if (deletedIncome)
                commit('SET_INCOME_TOTAL', state.incomesTotal - deletedIncome.amount)
        }

    }

}