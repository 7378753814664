const numeral = require('numeral')

export const sum = (values) => {
    let total = 0;
    values.forEach(value => total += parseFloat(value))
    return total
}

export const money = amount => {
    return numeral(parseFloat(amount).toFixed(2)).format('$0,0.00')
}

export const showAlert = (vm, options) => {
    if (options.permanent === undefined) options.permanent = false
    let notificationData = {
      permanent: options.permanent,
      id: options.id
    }
    if (options.html) notificationData.html = options.html
    vm.$notification.$emit(options.type, notificationData)
  }