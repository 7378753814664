import Vue from 'vue'
import Vuex from 'vuex'
import { FundsModule } from './modules/FundsModule'
import { IncomesModule } from './modules/IncomesModule'
import { SubscriptionsModule } from './modules/SubscriptionsModule'
import { ExpenseCategoriesModule } from './modules/ExpenseCategoriesModule'
import { BudgetsModule } from './modules/BudgetsModule'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
    modules: {
        FundsModule,
        IncomesModule,
        SubscriptionsModule,
        ExpenseCategoriesModule,
        BudgetsModule
    },
    strict: debug
})