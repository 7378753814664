<template>
  <div class="container">
    <div class="xl:flex justify-between">
      <FundsCard class="mb-4 w-full"></FundsCard>
      <IncomeCard class="mb-4 w-full"></IncomeCard>
      <SubscriptionCard class="mb-4 w-full"></SubscriptionCard>
    </div>
    <div class="xl:flex justify-between">
      <BudgetsCard class="mb-4 xl:w-2/3 "></BudgetsCard>
      <ExpenseCategoriesCard class="mb-4 xl:w-1/3"></ExpenseCategoriesCard>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    FundsCard: () => import("./FundsCard.vue"),
    IncomeCard: () => import("./IncomeCard.vue"),
    SubscriptionCard: () => import("./SubscriptionCard.vue"),
    BudgetsCard: () => import("./budgets/BudgetsCard.vue"),
    ExpenseCategoriesCard: () => import("./ExpenseCategoriesCard.vue")
  }
};
</script>

