const axios = require('axios')
import { sum } from '../../helpers'
import _ from 'lodash'

export const FundsModule = {
    namespaced: true,
    state: {
        funds: [],
        loadingFunds: false,
        fundsTotal: 0.0,
        defaultFund: null
    },
    getters: {
        getFunds: state => state.funds,
        getFundsTotal: state => state.fundsTotal,
        loadingFunds: state => state.loadingFunds,
        defaultFund: state => state.defaultFund
    },

    mutations: {
        SET_FUNDS(state, funds) {
            state.funds = funds
        },
        SET_FUNDS_TOTAL(state, total) {
            state.fundsTotal = total
        },
        SET_LOADING_STATUS(state, status) {
            state.loadingFunds = status
        },
        SET_DEFAULT_FUND(state, fund) {
            state.defaultFund = fund
        }
    },
    actions: {
        /**
         * Loads funds from the api
         * @param {*} param0
         * @param {*} force
         */
        async loadFunds({ commit, state, dispatch }, force = false) {
            if (state.funds.length > 0 && !force) return
            commit('SET_LOADING_STATUS', true)
            const funds = (await axios('/api/funds')).data.data
            commit('SET_FUNDS_TOTAL', sum(funds.map(fund => fund.balance)))
            commit('SET_FUNDS', funds)
            dispatch('setDefaultFund')
            commit('SET_LOADING_STATUS', false)
        },

        /**
         * Sets the default fund
         * @param {*} param0
         */
        setDefaultFund({ state, commit }) {
            const defaultMatch = state.funds.filter(fund => fund.is_default)
            if (defaultMatch.length > 0)
                commit('SET_DEFAULT_FUND', defaultMatch[0])
        },

        /**
         * Adds new fund
         * @param {*} param0
         * @param {*} payload
         */
        async addFund({ commit, state, dispatch }, payload) {
            const fund = (await axios.post('/api/funds', payload)).data.data
            // Update funds and total
            const funds = _.cloneDeep(state.funds)
            funds.push(fund)
            commit('SET_FUNDS', funds)
            commit('SET_FUNDS_TOTAL', state.fundsTotal + fund.balance)
            dispatch('setDefaultFund')
        },

        async makeFundDefault({ commit, state }, fundId) {
            await axios.put(`/api/funds/${fundId}`, { is_default: true })
            // Clear existing default and set new default
            let defaultFund = null
            const funds = _.cloneDeep(state.funds)
            funds.map(fund => {
                if (fund.is_default) fund.is_default = false
                if (fund.id === fundId) {
                    fund.is_default = true
                    defaultFund = fund
                }
            })

            commit('SET_FUNDS', funds)
            commit('SET_DEFAULT_FUND', defaultFund)

        },

        async setFundLiquidStatus({commit, state}, fund){
          const response = await axios.put(`/api/funds/${fund.id}`, { is_liquid: fund.is_liquid })
          console.log(response.data)

          const funds = _.cloneDeep(state.funds)
          const fundIndex = funds.findIndex(current => current.id === fund.id)
          if(fundIndex !== -1) {
            funds[fundIndex] = fund
            commit('SET_FUNDS', funds)
          }
        },

        /**
         * Remove fund from api
         * @param {*} param0
         * @param {*} fundId
         */
        async removeFund({ commit, state }, fundId) {
            await axios.delete(`/api/funds/${fundId}`)
            const deletedFund = state.funds.find(fund => fund.id === fundId)
            const funds = state.funds.filter(fund => fund.id !== fundId)
            commit('SET_FUNDS', funds)
            if (deletedFund)
                commit('SET_FUNDS_TOTAL', state.fundsTotal - deletedFund.balance)
        },

        /**
         * Processes fund transfer via the api
         * @param dispatch
         * @param payload
         * @returns {Promise.<void>}
         */
        async processFundTransfer({ dispatch }, payload){
            await axios.post('/api/funds/transfers', payload)
            dispatch('loadFunds', true)
        }

    }

}
